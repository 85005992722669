import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import SEO from '../components/seo'
import { useSiteMetaData } from '../utils'

export default ({ data, location }) => {
  const blog = data.mdx
  const { siteURL } = useSiteMetaData()

  return (
    <>
      <SEO
        title={blog.frontmatter.title}
        description={blog.excerpt}
        meta={[
          {
            name: 'og:image',
            content:
              siteURL + blog.frontmatter.thumbnail.childImageSharp.fluid.src,
          },
          {
            name: 'twitter:image',
            content:
              siteURL + blog.frontmatter.thumbnail.childImageSharp.fluid.src,
          },
        ]}
        path={location.pathname}
      />
      <Img
        fluid={blog.frontmatter.thumbnail.childImageSharp.fluid}
        alt={blog.frontmatter.title}
      />
      <h1>{blog.frontmatter.title}</h1>
      <MDXRenderer>{blog.body}</MDXRenderer>
    </>
  )
}

export const query = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      excerpt
      frontmatter {
        title
        date
        thumbnail {
          childImageSharp {
            fluid(maxWidth: 800, maxHeight: 300) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
